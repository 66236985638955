/* global variables */
/* when displayed over dark background */
.light {
  --prefixClass: rc-slider;
  --disabledColor: #ccc;
  --rail-bg-color: rgb(255, 255, 255, 0.2);
  --border-radius-base: 6px;
  --primary-color-60: rgb(45, 183, 245, 0.6);
  --primary-color-50: rgb(45, 183, 245, 0.5);
  --primary-color-20: rgb(45, 183, 245, 0.2);
  --inactive-text-mark-color: rgb(255, 255, 255, 0.4);
  --active-text-mark-color: #fff;
  --tooltip-color: #fff;
  --tooltip-bg: rgb(255, 255, 255, 0.2);
  --tooltip-arrow-width: 4px;
  --tooltip-distance: var(--tooltip-arrow-width)+4;
  --tooltip-arrow-color: var(--tooltip-bg);
  --ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
  --ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

/* display over light background */
.dark {
  --prefixClass: rc-slider;
  --disabledColor: #ccc;
  --rail-bg-color: #e5e7eb;
  --border-radius-base: 6px;
  --primary-color-60: rgb(45, 183, 245, 0.8);
  --primary-color-50: rgb(45, 183, 245, 0.7);
  --primary-color-20: rgb(45, 183, 245, 0.2);
  --inactive-text-mark-color: rgb(0, 0, 0, 0.4);
  --active-text-mark-color: #rgb(0, 0, 0, 0.9);
  --tooltip-color: #fff;
  --tooltip-bg: rgb(0, 0, 0, 0.7);
  --tooltip-arrow-width: 4px;
  --tooltip-distance: var(--tooltip-arrow-width)+4;
  --tooltip-arrow-color: var(--tooltip-bg);
  --ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
  --ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.rc-slider {
  position: relative;
  width: 100%;
  height: 14px;
  padding: 5px 0;
  border-radius: var(--border-radius-base);
  touch-action: none;
}

/* RAIL */

.rc-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: var(--rail-bg-color);
  border-radius: var(--border-radius-base);
}

/* TRACK - selected part of the rail */

.rc-slider-track {
  position: absolute;
  height: 4px;
  background-color: var(--primary-color-60);
  border-radius: var(--border-radius-base);
}

/* HANDLE */

.rc-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 2px var(--primary-color-50);
  border-radius: 50%;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  opacity: 0.8;
  touch-action: pan-x;
}

.rc-slider-handle-dragging {
  border-color: var(--primary-color-60);
  box-shadow: 0 0 0 5px var(--primary-color-50);
}

.rc-slider-handle:focus {
  outline: none;
  box-shadow: none;
}

.rc-slider-handle:focus-visible {
  border-color: var(--primary-color-60);
  box-shadow: 0 0 0 3px var(--primary-color-50);
}

.rc-slider-handle-click-focused:focus {
  border-color: var(--primary-color-50);
  box-shadow: unset;
}

.rc-slider-handle:hover {
  border-color: var(--primary-color-20);
}

.rc-slider-handle:active {
  border-color: var(--primary-color-20);
  box-shadow: 0 0 5px var(--primary-color-20);
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* MARKS (text labels) */

.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
}

.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: var(--inactive-text-mark-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}

.rc-slider-mark-text-active {
    color: var(--active-text-mark-color);
}

.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
  pointer-events: none;
}

.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  width: 8px;
  height: 8px;
  /* margin-left: -4px; */
  vertical-align: middle;
  background-color: #fff;
  border: 2px solid #e9e9e9;
  border-radius: 50%;
  cursor: pointer;
}

.rc-slider-dot {
  border-color: var(--primary-color-50);
}

.rc-slider-dot-reverse {
  margin-right: -4px;
}

/* TOOLTIP formatting */

.rc-tooltip {
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: visible;
  font-size: 0.75rem;
  margin-left: -2px;
  z-index: 50;
}
  /* .borderBox(); */

.rc-tooltip-hidden {
  display: none;
}

.rc-tooltip-placement-top {
  padding: var(--tooltip-arrow-width) 0 var(--tooltip-distance) 0;
}

.rc-tooltip-inner {
  min-width: 24px;
  height: 24px;
  padding: 4px;
  /* padding: 6px 2px; */
  color: var(--tooltip-color);
  text-align: center;
  text-decoration: none;
  background-color: var(--tooltip-bg);
  border-radius: var(--border-radius-base);
  /* box-shadow: 0 0 4px #d9d9d9; */
}

.rc-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.rc-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: var(--tooltip-distance) - var(--tooltip-arrow-width);
  left: 50%;
  margin-left: -var(--tooltip-arrow-width);
  border-width: var(--tooltip-arrow-width) var(--tooltip-arrow-width) 0;
  border-top-color: var(--tooltip-arrow-color);
}